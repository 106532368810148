import React from 'react'
import {a} from '@react-spring/web'

export default function Overlay({fill, textColor}) {
    
    const goToDannyMcwaves = (e) => {
        window.location.href = "https://www.mcwaves.holdings/blog"
    };
    
    // Just a Figma export, the fill is animated
    return (
        <div className="overlay">
            <a.svg onClick={goToDannyMcwaves} viewBox="0 0 583 720" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path fill={textColor} d="M 50.11 60.161 h 9 v 9 h -9 z M 45 51 h 9 v 9 h -9 z M 39.263 60.161 h 9 v 9 h -9 z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M 60.11 60.161 h 9 v 9 h -9 z M 55 51 h 9 v 9 h -9 z M 49.263 60.161 h 9 v 9 h -9 z" fill={textColor}/>
                <text style={{whiteSpace: 'pre'}} fontFamily="Inter" fontSize={10} letterSpacing="-.02em">
                    <tspan x={470} y={70} children={new Date().toUTCString()}/>
                </text>
                <text style={{whiteSpace: 'pre'}} fontFamily="Inter" fontSize={10.5} fontWeight={500}
                      letterSpacing="0em">
                    <tspan x={40} y={175.318} children="FUTURE OF "/>
                    <tspan x={40} y={188.318} children="HOLDINGS COMPANIES AND INDEX FUNDS"/>
                </text>
                <text fill={textColor} style={{whiteSpace: 'pre'}} fontFamily="Inter" fontSize={40} fontWeight="bold"
                      letterSpacing="0em">
                    <tspan x={40} y={257.909} children={'MCWAVES HOLDINGS LLC \u2014'}/>
                </text>
                <text style={{whiteSpace: 'pre'}} fontFamily="Inter" fontSize={12} fontWeight="bold"
                      letterSpacing="0em">
                    <tspan x={40} y={270.909}/>
                </text>
                <text style={{whiteSpace: 'pre'}} fontFamily="Inter" fontSize={16.5} letterSpacing="0em">
                    <tspan x={40} y={290.909} children="Of all things a man cannot beat, time is the cruelest and most unfair. "/>
                    <tspan x={40} y={322.909} children="We always think there'll come a perfect time when we're ready to execute "/>
                    <tspan x={40} y={353.909} children="our plans or a perfect moment in time when we have all that we desire, "/>
                    <tspan x={40} y={384.909} children="But, that time never comes! "/>
                    <tspan x={40} y={415.909} children="We eventually run out of time, leaving us with nothing but dead dreams. "/>
                    <tspan x={40} y={446.909} children="It'll be a sad sight to grow older and become unrecognizable to oneself; "/>
                    <tspan x={40} y={477.909} children="To have the very lives we imagined become a pipe dream. "/>
                    <tspan x={40} className={"clickSpan"} fill={textColor} y={520.318} children="Join me"/>
                    <tspan x={103} y={520.318} children=" on my "/>
                    <tspan x={161} className={"clickSpan"} fill={textColor} y={520.318} children="Journey"/>
                    <tspan x={230} y={520.318} children="to "/>
                    <tspan x={250} className={"clickSpan"} fill={textColor} y={520.318} children="Explore More"/>
                    <tspan x={355} y={520.318} children=" or just "/>
                    <tspan x={415} className={"clickSpan"} fill={textColor} y={520.318} children="get in touch."/>
                </text>
                <text style={{whiteSpace: 'pre'}} fontFamily="Inter" fontSize={10.5} fontWeight={500}
                      letterSpacing="0em">
                    <tspan x={326} y={640.318} children="© A Holdings company by "/>
                    <tspan href={"https://www.dannymcwaves.com"} target={"_blank"} className={"clickSpan"} onClick={goToDannyMcwaves} style={{TextDecoration: "underline"}} fill={"blue"} x={460} y={640.318} children="Danny Mcwaves "/>
                </text>
            </a.svg>
        </div>
    )
}
