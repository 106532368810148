import React, {useEffect} from 'react'
import {Canvas} from '@react-three/fiber'
import {OrbitControls} from '@react-three/drei'
import {useSpring} from '@react-spring/core'
import {a} from '@react-spring/web'
import Overlay from './Overlay'
import Scene from './Scene'

export default function App() {
    // This spring controls the background and the svg fill (text color)
    const [{background, fill}, set] = useSpring({background: '#f0f0f0', fill: '#202020'}, []);
    const textColor = "#e84174";
    
    useEffect(() => {
        console.log("starting app");
        window.document.body.addEventListener('click', (e) => {
            console.log(e.target);
        }, false);
        const el = window.document.querySelectorAll('tspan.clickSpan');
        el.forEach((e) => {
            console.log(e);
            e.style.pointerEvents='all';
            e.addEventListener("click", (ev) => {
                console.log(ev);
            }, true);
        });
        // el.dispatchEvent(new Event('click',{bubbles:true}))
    }, []);
    
    return (
        <a.main style={{background}}>
            <Canvas className="canvas" dpr={[1, 2]}>
                <Scene setBg={set} setFg={textColor}/>
                <OrbitControls enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2}
                               minPolarAngle={Math.PI / 2}/>
            </Canvas>
            <Overlay textColor={textColor} fill={fill}/>
        </a.main>
    )
}
